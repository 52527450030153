import { useMoodboardState } from "./useMoodBoardState";
import { initialArray, optimizeImage } from "../utils/commonUtils";
import { useEffect } from "react";
import { getProductData } from "../services/productService";
import {
  DraggedImageData,
  Placement,
  Product,
  ProductResult,
} from "../state/types";
import { emitProductAdded, emitProductRemoved } from "../utils/moodboardEvents";

export const useEmptyTemplateHandler = () => {
  const {
    apiConfig,
    moodboardConfig,
    isDropdownOpenIndex,
    imageData,
    draggedImageData,
    projects,
    activeProjectIndex,
    templateCoordinate,
    isTemplateLoading,
    setImageData,
    setDraggedImageData,
    setIsDropdownOpenIndex,
    setTemplate,
  } = useMoodboardState();

  const callbackEmit = moodboardConfig?.debugMode ? console.log : () => {}
  const initialEmptyPlacements : number = moodboardConfig?.emptyTemplate || 1

  const templateIndex = Math.min(
    imageData.length,
    templateCoordinate.length - 1
  );

  const templateArray = templateCoordinate[templateIndex]?.map(
    (ele: Placement, index: number) => {
      return { ...ele, id: index }; // An ID is needed for proper rendering and to resolve index issues
    }
  );

  const imageDataArray = templateArray?.map((placement, index) => ({
    ...imageData[index],
    ...placement,
  }));

  const invalidIndex = imageDataArray?.findIndex(
    (ele) => !ele.product?.skuId
  );

  const isImageIsAvailableAtLast = imageDataArray?.[imageDataArray?.length - 1]?.product?.skuId

  const imageNewDataArray = !(initialEmptyPlacements > imageDataArray?.length - 1) && !isImageIsAvailableAtLast && invalidIndex > -1 && invalidIndex !== imageDataArray?.length - 1
                            ? imageDataArray?.filter((ele, idx) => !(idx === imageDataArray?.length - 1))
                            : imageDataArray;
    
  const dynamicStyle = imageData[templateIndex];

  useEffect(() => {
    const newImageData: Placement[] = projects[activeProjectIndex]?.data;
    if (newImageData) {
      const template = projects[activeProjectIndex]?.template;
      if (template) {
        setTemplate(template);
      }
    }
    setIsDropdownOpenIndex(null);
    setImageData(newImageData || initialArray(initialEmptyPlacements));
  }, [activeProjectIndex, projects, templateCoordinate, initialEmptyPlacements]);

  const handleAddToTrey = async (
    addToTreyImageData: DraggedImageData | null,
    targetIndex: string | null,
  ) => {

    const index = findIndex(targetIndex);

    const canDropTile =
      imageData.length < templateCoordinate.length || imageData.length === templateCoordinate.length && index > -1
      imageData.filter((ele) => ele?.product?.skuId).length < imageData.length;

    if (addToTreyImageData && canDropTile) {
      const loadingImage: Placement = {
        product: {
          src: "",
          applications: [],
          loading: true,
          skuId: addToTreyImageData?.product.skuId,
          title: "",
        },
        x: 0,
        y: 0,
        layer: 0,
        height: 0,
        width: 0,
        id: imageData.length,
      };

      imageData?.length === index ||
      index === -1
        ? setImageData((prev: Placement[]) => [...prev, loadingImage])
        : setImageData((prev) =>
            replaceEmptySlot(prev, index, {
              src: "",
              applications: [],
              loading: true,
              skuId: addToTreyImageData?.product.skuId,
              title: "",
            })
          );

      try {
        const result = (await getProductData({
          skuId: addToTreyImageData?.product.skuId,
          endpoint: apiConfig?.apiEndpoint?.productService || "",
          ...moodboardConfig?.params,
        })) as ProductResult;

        const mediaData = result?.mediaData
        const optionsData = result?.optionsData
        const title = result?.title
        const skuId = result?.skuId
        
        const fetchedImage: Product = {
          src: optimizeImage({
            url: mediaData.mediaFindOne.value[0].value,
            imageWidth: dynamicStyle?.width || 0,
          }),
          applications: optionsData?.value || [],
          title,
          loading: false,
          skuId,
        };

        emitProductAdded(skuId, callbackEmit);

        setImageData((prev: Placement[]) =>
          replaceLoadingImage(prev, fetchedImage)
        );

        setDraggedImageData(null);
        setIsDropdownOpenIndex(index > -1 ? index : imageData.length);
      } catch (error) {
        // console.error("fetch productData error", error);
        setImageData(imageData);
      }
    }
  };

  const findIndex = (targetIndex: string | null) => {
    let index
    if (targetIndex !== null) {
      index =
      targetIndex !== null && Number(targetIndex) >= 0
        ? Number(targetIndex)
        : imageData.findIndex((ele) => !ele.product?.skuId) || 0;
    } else {
      if (isDropdownOpenIndex !== null) {
        if (imageData[isDropdownOpenIndex]?.product?.skuId) {
          index = imageData.findIndex((ele) => !ele.product?.skuId) || 0;
        } else {
          index = isDropdownOpenIndex;
        }
      }
      else{
        index = imageData.filter((ele) => ele?.product?.skuId).length === imageData.length ? -1 : 0
      }
    }
    return index || 0
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const targetIndex = target.getAttribute("data-index");
    await handleAddToTrey(draggedImageData, targetIndex);
  };

  const handleDuplicate = (index: number, callback: (value: boolean) => void): void => {
    const canDuplicateTile = imageData?.length < templateCoordinate.length;
    if (canDuplicateTile) {
      setImageData((prev) => {
        const imageToDuplicate = prev[index];
        if (imageToDuplicate) {
          setIsDropdownOpenIndex(index + 1);
          return [
            ...prev.slice(0, index + 1),
            imageToDuplicate,
            ...prev.slice(index + 1),
          ];
        }
        return prev;
      });
    } else {
      callback(true); // Ensure callback expects a boolean
    }
  };

  const handleRemove = (index: number) => {
    // setImageData((prev) =>
    //   prev.map((ele, idx) => {
    //     return index === idx ? { ...ele, product: { skuId: "" } } : ele;
    //   }).filter(ele => ele?.selectedApplication ||  ele?.product?.skuId)
    // );

    setImageData((prev) =>
      prev.filter((_, idx) => {
        return index !== idx
      })
    );
    setIsDropdownOpenIndex(null);

    const sku = imageData[index]?.product?.skuId || "";
    emitProductRemoved(sku, callbackEmit);
  };

  return {
    handleAddToTrey,
    handleDrop,
    handleDuplicate,
    handleRemove,
    imageDataArray: isTemplateLoading ? [] : imageNewDataArray,
  };
};

const replaceLoadingImage = (prev: Placement[], newImage: Product) => {
  return prev.map((ele, idx) => {
    return ele?.product?.loading && ele?.product?.skuId === newImage?.skuId
      ? { ...ele, product: newImage }
      : ele;
  });
};

const replaceEmptySlot = (
  prev: Placement[],
  index: number,
  newImage: Product
) => {
  return prev.map((ele, idx) => {
    return idx === index ? { ...ele, product: newImage } : ele;
  });
};
