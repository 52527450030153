// src/components/Moodboard.tsx

import React from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
} from "@chakra-ui/react";
import Header from "./Header";
import DropZone from "./DropZone";
import Footer from "./Footer";
import { observer } from "@legendapp/state/react";
import { useMoodboardHandler } from "../hooks/useMoodBoardHandler";
import { Placement } from "../state/types";

const Moodboard: React.FC = observer(() => {
  const {
    isEmptyTemplate,
    imageDataArray,
    isOpen,
    moodboardConfig,
    isDropdownOpenIndex,
    draggedImageData,
    onClose,
    handleDrop,
    handleToggleDropdown,
    handleUpdateCheckedOptions,
    handleDuplicate,
    handleRemove,
    handleSaveProject,
    handleSwitchProjects,
    setImageData,
    setIsDropdownOpenIndex,
  } = useMoodboardHandler();

  const handleSetImage = (data: Placement[] | ((prev: Placement[]) => Placement[])) => {
    setImageData(data)
  }

  return (
    <Popover
      placement="bottom-end"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverContent
        sx={
          moodboardConfig?.classes?.trayContent
            ? {
                right: moodboardConfig?.right,
                bottom: moodboardConfig?.bottom,
                top: moodboardConfig?.top,
                left: moodboardConfig?.left,
                height: moodboardConfig?.height || "auto",
                width: moodboardConfig?.width || "345px",
                position: "fixed",
                fontFamily: moodboardConfig?.fontFamily,
                borderColor: 'rgb(226, 232, 240)',
              }
            : {
                ...styles.trayContent,
                right: moodboardConfig?.right,
                bottom: moodboardConfig?.bottom,
                top: moodboardConfig?.top,
                left: moodboardConfig?.left,
                height: moodboardConfig?.height || "auto",
                width: moodboardConfig?.width || "345px",
                position: "fixed",
                fontFamily: moodboardConfig?.fontFamily,
                borderColor: 'rgb(226, 232, 240)',
              }
        }
        className={moodboardConfig?.classes?.trayContent || ""}
      >
        <Header
          onClose={onClose}
          onSave={handleSaveProject}
          handleSwitchProjects={handleSwitchProjects}
        />
        <PopoverArrow
          className={moodboardConfig?.classes?.trayArrow || ""}
          sx={moodboardConfig?.classes?.trayArrow ? {} : styles.trayArrow}
        />
        <PopoverBody
          sx={moodboardConfig?.classes?.trayBody ? {} : styles.trayBody}
          className={moodboardConfig?.classes?.trayBody || ""}
        >
          <DropZone
            {...{
              imageDataArray,
              isDropdownOpenIndex,
              setIsDropdownOpenIndex,
              handleDrop,
              handleToggleDropdown,
              handleUpdateCheckedOptions,
              handleDuplicate,
              handleRemove,
              isEmptyTemplate,
              draggedImageData,
              setImageData:handleSetImage,
            }}
          />
        </PopoverBody>
        <Footer imageDataArray={imageDataArray} />
      </PopoverContent>
    </Popover>
  );
});

export default React.memo(Moodboard);

const styles = {
  trayArrow: {},
  trayContent: {
    overflow: "hidden",
  },
  trayBody: {
    height: "100%",
    padding: "0",
  },
};