import packageJson from "../../package.json";

export const getApiEndpoint = async (configPath: string) => {
  try {
    // Normalize the configPath to ensure it doesn't have a trailing slash
    const normalizedConfigPath = configPath?.endsWith("/")
      ? configPath.slice(0, -1)
      : configPath;

    // Construct the URL for the configuration file
    const url = normalizedConfigPath
      ? `${normalizedConfigPath}/config.json`
      : `https://studio.byondxr.com/products/moodboard/dev/${packageJson?.version}/config.json`;

    // Fetch the configuration file
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch apiEndpoint from server: ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Error fetching apiEndpoint from API, using local configuration:",
      error
    );
  }
};
