import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { CartPlusIcon, DashboardIcon } from "../utils/svgUtils";
import { emitOrderSample, emitVisualizerLaunched } from "../utils/moodboardEvents";
import { Placement } from "../state/types";
import { observer } from "@legendapp/state/react";
import { moodboardConfigState } from "../state/store";

interface FooterProps {
  imageDataArray: Placement[];
}

const Footer: React.FC<FooterProps> = observer(({ imageDataArray }) => {

  const moodboardConfig = moodboardConfigState.get();

  const callbackEmit = moodboardConfig?.debugMode ? console.log : () => {}
  

  return (
    <Box
      sx={moodboardConfig?.classes?.footer ? {} : styles.footerContainer}
      className={moodboardConfig?.classes?.footer || ""}
    >
      <Button
        sx={moodboardConfig?.classes?.footerButton && moodboardConfig?.classes?.footerButtonPrimary ? {} : { ...styles.footerButton, ...styles.footerButtonPrimary }}
        onClick={() => {
          emitVisualizerLaunched(imageDataArray, callbackEmit);
        }}
        className={`${moodboardConfig?.classes?.footerButton || ""} ${
          moodboardConfig?.classes?.footerButtonPrimary || ""
        }`}
      >
        <Box
          sx={moodboardConfig?.classes?.footerIcon ? {} : styles.footerIcon}
          className={moodboardConfig?.classes?.footerIcon || ""}
        >
          <DashboardIcon />
        </Box>
        <Text
          sx={moodboardConfig?.classes?.footerText && moodboardConfig?.classes?.footerTextPrimary ? {} :  { ...styles.footerText, ...styles.footerTextPrimary }}
          className={`${moodboardConfig?.classes?.footerText || ""} ${moodboardConfig?.classes?.footerTextPrimary || ""}`}
        >
          Visualize
        </Text>
      </Button>
      <Button
        sx={moodboardConfig?.classes?.footerButton && moodboardConfig?.classes?.footerButtonSecondary ? {} : { ...styles.footerButton, ...styles.footerButtonSecondary }}
        onClick={() => {
          emitOrderSample(imageDataArray, callbackEmit);
        }}
        className={`${moodboardConfig?.classes?.footerButton || ""} ${
          moodboardConfig?.classes?.footerButtonSecondary || ""
        }`}
      >
        <Box
          sx={moodboardConfig?.classes?.footerIcon ? {} : styles.footerIcon}
          className={moodboardConfig?.classes?.footerIcon || ""}
        >
          <CartPlusIcon />
        </Box>
        <Text
          sx={moodboardConfig?.classes?.footerText &&  moodboardConfig?.classes?.footerTextSecondary ? {} : { ...styles.footerText, ...styles.footerTextSecondary }}
          className={`${moodboardConfig?.classes?.footerText || ""} ${moodboardConfig?.classes?.footerTextSecondary || ""}`}
        >
          Order Samples
        </Text>
      </Button>
    </Box>
  );
});

export default Footer;

const styles = {
  footerContainer: {
    padding: "13px 16px",
    backgroundColor: "#f5f6fa",
    display: "flex",
    gap: "16px",
  },
  footerButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderRadius: "0.375rem",
    transition: "background 0.3s",
  },
  footerButtonPrimary: {
    backgroundColor: "var(--gray-100, #F5F6FA)",
    border: "1px solid var(--secondary, #27292C)",
    ":hover": { backgroundColor: "var(--secondary, #27292C20)" },
  },
  footerButtonSecondary: {
    backgroundColor: "var(--secondary, #27292C) !important",
    border: "1px solid var(--secondary, #27292C)",
    ":hover": { backgroundColor: "var(--secondary, #27292CCC) !important" },
  },
  footerIcon: {
    width: "18px",
    height: "18px",
  },
  footerText: {
    fontSize: "12px",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "0.12px",
  },
  footerTextPrimary: {
    color: "var(--gray-900, #27292C)",
  },
  footerTextSecondary: {
    color: "var(--grey-50, #FFF)",
  },
};
