import { useEffect } from "react";
import { useMoodboardState } from "./useMoodBoardState";
import { getTemplate } from "../services/templateService";
import { useDisclosure } from "@chakra-ui/react";
import {
  Config,
  DraggedImageData,
  Project,
  Placement,
  TemplateConfig,
  TemplateData,
  ApiConfig,
} from "../state/types";
import { ProductResult } from "../state/types";
import { useEmptyTemplateHandler } from "./useEmptyTemplateHandler";
import { useDefaultTemplateHandler } from "./useDefaultTemplateHandler";
import { getTrayStatus, initialArray, optimizeImage } from "../utils/commonUtils";
import {
  emitApplicationChanged,
  emitApplicationSelected,
  emitNewProject,
  emitProjectSaved,
  emitProjectSelected,
  emitTrayOpened,
  EVENTS,
  setMoodboardSDK
} from "../utils/moodboardEvents";
import { getApiEndpoint } from "../services/apiEndpointService";
import { getProductData } from "../services/productService";

export const useMoodboardHandler = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    apiConfig,
    imageData,
    template,
    templateCoordinate,
    moodboardConfig,
    isDropdownOpenIndex,
    draggedImageData,
    projects,
    activeProjectIndex,
    projectName,
    isTemplateLoading,
    setApiConfig,
    setMoodboardConfigState,
    setDraggedImageData,
    setTemplateCoordinate,
    setIsTemplateLoading,
    setTemplate,
    setImageData,
    setIsDropdownOpenIndex,
    setProjects,
    setActiveProjectIndex,
    setProjectName,
  } = useMoodboardState();

  const isEmptyTemplate = template.key === "empty_template";

  const {
    handleAddToTrey,
    handleDrop,
    handleDuplicate,
    handleRemove,
    imageDataArray,
  } = isEmptyTemplate ? useEmptyTemplateHandler() : useDefaultTemplateHandler();

  const callbackEmit = moodboardConfig?.debugMode ? console.log : () => {}

  const configPath = moodboardConfig?.configPath

  useEffect(() => {
    const loadApiEndpoint = async () => {
      try {
        const configRes: ApiConfig | null = await getApiEndpoint(configPath);

        if (configRes) {
          setApiConfig(configRes);
        }
        
      } catch (error) {
        moodboardConfig?.debugMode &&
          console.error("Failed to load template:", error);
      }
    };

    loadApiEndpoint();
  }, [configPath]);

  useEffect(() => {
    isOpen && emitTrayOpened(callbackEmit);
  }, [isOpen]);

  useEffect(() => {
    const handleMessage = async (event: Event) => {
      const customEvent = event as CustomEvent;
      const { data, callback } = customEvent.detail;

      if (data && data.eventName) {
        isOpen &&
          moodboardConfig?.debugMode &&
          console.log(`Received Moodboard Event: ${data.eventName}`, data);
          
        switch (data.eventName) {
          case EVENTS.INIT:
            handleProjectInit(data?.config);
            break;
          case EVENTS.DRAG_START:
            isOpen && await handleGetProductData(data);
            break;
          case EVENTS.SET_TILE:
            isOpen && await handleAddProductData(data);
            break;
          case EVENTS.SET_PROJECTS:
            isOpen && await handleLaunchProjects(data);
            break;
          case EVENTS.SET_TEMPLATE:
            isOpen && handleSetTemplate(data);
            break;
          case EVENTS.RESET_TRAY:
            isOpen && handleResetTray();
            break;
          default:
            isOpen &&
              moodboardConfig?.debugMode &&
              console.warn(`Unknown event received: ${data.eventName}`);
        }
      }

      if (callback && typeof callback === "function") {
        await callback("Callback invoked successfully!");
      }
    };

    window.addEventListener("staticEvent", handleMessage);

    return () => {
      window.removeEventListener("staticEvent", handleMessage);
    };
  }, [isOpen, imageData, templateCoordinate, apiConfig, isDropdownOpenIndex]);

  const trayStatus = getTrayStatus( projects, activeProjectIndex, imageDataArray ,templateCoordinate, isEmptyTemplate)

  useEffect(() => {
    setMoodboardSDK({
      onOpen,
      onClose,
      imageData,
      projects,
      activeProjectIndex,
      trayStatus,
      template,
      projectName
    });
  }, [onOpen, onClose, imageData, projects, activeProjectIndex, apiConfig, trayStatus, template, projectName]);

  // Load template asynchronously
  useEffect(() => {
    const loadTemplate = async () => {
      setIsTemplateLoading(true);
      try {
        const loadedTemplate: TemplateData = await getTemplate(
          template.key || "empty_template",
          apiConfig?.apiEndpoint?.templateService || ""
        );
        setTemplateCoordinate(loadedTemplate?.placement);
        setProjectName(loadedTemplate.name);
      } catch (error) {
        moodboardConfig?.debugMode &&
          console.error("Failed to load template:", error);
      } finally {
        setIsTemplateLoading(false);
      }
    };
    loadTemplate();
  }, [template]);

  // Load template asynchronously

  const handleResetTray = () => {
    const initialEmptyPlacements : number = moodboardConfig?.emptyTemplate || 1

    setImageData(initialArray(initialEmptyPlacements))
    setActiveProjectIndex(null)
    setTemplate({key:"empty_template"});
  } 

  const handleProjectInit = (data: Config) => {
    setMoodboardConfigState(data);
    setTemplate({ key: data?.template || "empty_template" });
  };

  const handleGetProductData = async (data: DraggedImageData) => {
    try {
      if (isEmptyTemplate) {
        setDraggedImageData(data);
        return data;
      } else {
        setDraggedImageData(null);
        const fetchData = await getProductData({
          skuId: data.product.skuId,
          endpoint: apiConfig?.apiEndpoint?.productService || "",
          ...moodboardConfig?.params,
        });
        if (fetchData) {
          const { mediaData, optionsData, title, skuId } =
            fetchData as ProductResult;
          setDraggedImageData({
            ...data,
            data: { mediaData, optionsData, title, skuId },
          });
          return { ...data, data: { mediaData, optionsData, title, skuId } };
        }
      }
    } catch (error) {
      moodboardConfig?.debugMode &&
        console.error("Error while getting product data:", error);
    }
  };

  const handleAddProductData = async (data: DraggedImageData) => {
    try {
      const productData = await handleGetProductData(data);
      if (productData) {
        await handleAddToTrey(productData, null);
      }
    } catch (error) {
      moodboardConfig?.debugMode &&
        console.error("Error while adding product data:", error);
    }
  };

  const handleToggleDropdown = (e: React.MouseEvent, index: number | null) => {
    e.stopPropagation();
    setIsDropdownOpenIndex(isDropdownOpenIndex !== index ? index : null);
  };

  const handleUpdateCheckedOptions = (
    index: number,
    selectedApplication?: string
  ) => {
    if (selectedApplication) {
      setIsDropdownOpenIndex(null);
      const updatedImageData = imageData.map((ele, i) =>
        i === index ? { ...ele, selectedApplication } : ele
      );
      setImageData(updatedImageData);

      const isOptionSelected = imageData[index]?.selectedApplication;

      if (isOptionSelected) {
        emitApplicationChanged(isOptionSelected, selectedApplication, callbackEmit);
      } else {
        emitApplicationSelected(selectedApplication, callbackEmit);
      }
    }
  };

  const handleSetTemplate = (data: {
    template: { key: keyof TemplateConfig };
  }) => {
    setTemplate(data.template);
  };

  const handleResetProjects = () => {
    setProjects([]);
    setActiveProjectIndex(null);
  };

  const handleSetProject = (initialProjects: Project[], index: number) => {
    const projects = initialProjects.map(project => {
      return project.project_type === "moodboard" ? project : {...project, template:{key:"empty_template"}}
    })
    setProjects(projects);
    setActiveProjectIndex(index);
    setProjectName(projects[index]?.name);
  };

  const handleLaunchProjects = async (setup: {
    projects: Project[];
    activeProjectIndex?: number | null;
  }) => {
    if (
      setup.projects?.length &&
      setup.activeProjectIndex !== null &&
      setup.activeProjectIndex !== undefined
    ) {
      const projects = setup.projects;
      const activeProjectIndex = setup.activeProjectIndex;
      if (imageData?.length > 0) {
        const draftProject: Project = {
          id: "",
          name: projectName || "",
          template: template,
          project_type:"moodboard",
          data: imageData,
        };
        handleLoadingProject([...projects, draftProject], activeProjectIndex);
      } else {
        handleLoadingProject(projects, activeProjectIndex);
      }
    } else {
      handleResetProjects();
    }
  };

  const handleLoadingProject = async (
    initialProjects: Project[],
    index: number
  ) => {
    const activeProject = initialProjects[index];
    const projects = [...initialProjects];

    const data = activeProject.data;

    const loadingData: Placement[] = data.map((tile: Placement) => {
      return {
        ...tile,
        product: {
          skuId: tile?.product?.skuId || "",
          applications: [],
          src: "",
          title: "",
          loading: true,
        },
      };
    });
    const loadingProject = { ...activeProject, data: loadingData };

    projects[index] = loadingProject;
    handleSetProject(projects, index);

    await handleFetchTileData(projects, index);
  };

  const handleFetchTileData = async (
    loadingProject: Project[],
    index: number
  ) => {
    const projects: Project[] = [...loadingProject];
    const project: Project = projects[index];

    // Use Promise.all to ensure all asynchronous operations are handled properly
    const dataWithFetchedResult = await Promise.all(
      project.data.map(async (tile: Placement) => {
        const { product } = tile;

        if (product?.skuId) {
          try {
            const result = (await getProductData({
              skuId: product?.skuId,
              endpoint: apiConfig?.apiEndpoint?.productService || "",
              ...moodboardConfig?.params,
            })) as ProductResult;


            const mediaData = result?.mediaData
            const optionsData = result?.optionsData
            const title = result?.title
            const skuId = result?.skuId

            const fetchedImage = {
              ...tile,
              product: {
                skuId: product?.skuId || "",
                src: optimizeImage({
                  url: mediaData.mediaFindOne.value[0].value,
                  imageWidth: tile.width || 0,
                }),
                applications: optionsData?.value || [],
                title,
                loading: false,
              },
            };

            return fetchedImage;
          } catch (error) {
            moodboardConfig?.debugMode &&
              console.error("Error fetching product data:", error);
            return {
              ...tile,
              product: {
                skuId: tile?.product?.skuId || "",
                applications: [],
                src: "",
                title: "",
                loading: false,
              },
            };
          }
        } else {
          return {
            ...tile,
            product: {
              skuId: tile?.product?.skuId || "",
              applications: [],
              src: "",
              title: "",
              loading: false,
            },
          };
        }
      })
    );

    const projectWithFetchedResult = {
      ...project,
      data: dataWithFetchedResult,
    };
    projects[index] = projectWithFetchedResult;

    handleSetProject(projects, index);
  };

  const handleSwitchProjects = (index: number): void => {
    const switchProjectsCallback = (msg: string) => {
      moodboardConfig?.debugMode && console.log(msg);
      handleLoadingProject(projects, index);
    };

    const newProjectsCallback = (msg: string) => {
      moodboardConfig?.debugMode && console.log(msg);
      setProjectName("");
      setActiveProjectIndex(index);
    };

    if (projects.length > index) {
      const { id, name } = projects[index];
      emitProjectSelected(id, name, switchProjectsCallback);
    } else {
      emitNewProject(newProjectsCallback);
    }
  };

  const handleSaveProject = () => {
    setIsDropdownOpenIndex(null);

    const updatedProjects: Project[] = [...projects];

    const projectId = projects[activeProjectIndex]?.id || "";
    const projectName = projects[activeProjectIndex]?.name || "";

    const savedProject = {
      id: projectId,
      name: projectName,
      template: template,
      data: imageData,
      project_type:"moodboard",
    };

    const index = activeProjectIndex || 0;
    updatedProjects[index] = savedProject;
    // setProjects(updatedProjects);

    moodboardConfig?.debugMode && console.log("saved project", savedProject);

    emitProjectSaved(savedProject, callbackEmit);
  };

  return {
    isEmptyTemplate,
    imageDataArray,
    isOpen,
    moodboardConfig,
    isDropdownOpenIndex,
    draggedImageData,
    onClose,
    handleDrop,
    handleToggleDropdown,
    handleUpdateCheckedOptions,
    handleDuplicate,
    handleRemove,
    handleSaveProject,
    handleSwitchProjects,
    setImageData,
    setIsDropdownOpenIndex,
  };
};
