import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { runPlayGround } from './test';

const start = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('moodboard') as HTMLElement
  );

  root.render(
    <ChakraProvider resetScope={'.mb-widget'} disableGlobalStyle={true}>
      <App />
    </ChakraProvider>
  );
}

window.onload = () => {
  setTimeout(() => {
    start();
  }, 500);
  runPlayGround();
};

reportWebVitals();
