import React from "react";
import {
  Box,
  Flex,
  HStack,
  PopoverHeader,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CrossIcon, PlusIcon, RevertIcon, SaveIcon } from "../utils/svgUtils";
import { useMoodboardState } from "../hooks/useMoodBoardState";
import { moodboardConfigState } from "../state/store";
import { emitReset, emitTrayClosed } from "../utils/moodboardEvents";

interface HeaderProps {
  onClose: () => void;
  onSave: () => void;
  handleSwitchProjects: (opt: number) => void;
}

const Header: React.FC<HeaderProps> = ({
  onClose,
  onSave,
  handleSwitchProjects,
}) => {
  const moodboardConfig = moodboardConfigState.get();

  const { projects, activeProjectIndex, projectName } = useMoodboardState();

  const handleProjectChange = (selectedOption: number) => {
    handleSwitchProjects(selectedOption);
  };

  // Determine whether to show "Hide" text or CrossIcon
  const hideText = useBreakpointValue({ base: true, sm: false });

  const callbackEmit = moodboardConfig?.debugMode ? console.log : () => {}

  return (
    <PopoverHeader
      sx={moodboardConfig?.classes?.header ? {} : styles.header}
      className={moodboardConfig?.classes?.header || ""}
    >
      <Flex
        sx={moodboardConfig?.classes?.headerContainer ? {} : styles.headerContainer}
        className={moodboardConfig?.classes?.headerContainer || ""}
      >
        <Box
          sx={moodboardConfig?.classes?.headerTitleBox ? {} : styles.headerTitleBox}
          className={moodboardConfig?.classes?.headerTitleBox || ""}
        >
          {projects && projects.length > 0 ? (
            <Menu closeOnSelect={true}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                sx={moodboardConfig?.classes?.headerSelect ? {} : styles.headerSelect}
                className={moodboardConfig?.classes?.headerSelect || ""}
              >
                <Text
                  sx={moodboardConfig?.classes?.headerSelectText ? {} : styles.headerSelectText}
                  className={moodboardConfig?.classes?.headerSelectText || ""}
                  noOfLines={2}
                >
                  {projects[activeProjectIndex]?.name ||
                    projectName ||
                    "Project Name"}
                </Text>
              </MenuButton>
              <MenuList
                sx={moodboardConfig?.classes?.headerOptionBox ? {} : styles.headerOptionBox}
                className={moodboardConfig?.classes?.headerOptionBox || ""}
              >
                <MenuOptionGroup
                  type="radio"
                  value={String(activeProjectIndex)}
                >
                  {projects.map((project, index) => (
                    <MenuItemOption
                      key={index}
                      value={String(index)}
                      onClick={() => handleProjectChange(index)}
                      sx={moodboardConfig?.classes?.headerOption ? {} : styles.headerOption}
                      className={moodboardConfig?.classes?.headerOption || ""}
                    >
                      <Text
                        sx={moodboardConfig?.classes?.headerOptionText ? {} : styles.headerOptionText}
                        className={
                          moodboardConfig?.classes?.headerOptionText || ""
                        }
                      >
                        {project.name || "Draft Project"}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          ) : (
            <Text
              sx={moodboardConfig?.classes?.headerTitleText ? {} : styles.headerTitleText}
              className={moodboardConfig?.classes?.headerTitleText || ""}
              noOfLines={2}
            >
              {projectName || "New Project"}
            </Text>
          )}
        </Box>
        <Box
          sx={moodboardConfig?.classes?.headerActionBox ? {} : styles.headerActionBox}
          className={moodboardConfig?.classes?.headerActionBox || ""}
        >
          <HStack
            sx={moodboardConfig?.classes?.headerIconBox ? {} : styles.headerIconBox}
            className={moodboardConfig?.classes?.headerIconBox || ""}
          >
            <Box
              sx={moodboardConfig?.classes?.headerIcon ? {} : styles.headerIcon}
              cursor="pointer"
              onClick={() => handleSwitchProjects(projects?.length)}
              className={moodboardConfig?.classes?.headerIcon || ""}
            >
              <PlusIcon />
            </Box>
            <Box
              sx={moodboardConfig?.classes?.headerIcon ? {} : styles.headerIcon}
              cursor="pointer"
              onClick={onSave}
              className={moodboardConfig?.classes?.headerIcon || ""}
            >
              <SaveIcon />
            </Box>
            <Box
              sx={moodboardConfig?.classes?.headerIcon ? {} : styles.headerIcon}
              cursor="pointer"
              onClick={() => emitReset(callbackEmit)}
              className={moodboardConfig?.classes?.headerIcon || ""}
            >
              <RevertIcon />
            </Box>

            {hideText ? (
              <Text
                onClick={() => {
                  emitTrayClosed(callbackEmit)
                }}
                sx={moodboardConfig?.classes?.headerHiddenText ? {} : styles.headerHiddenText}
                className={moodboardConfig?.classes?.headerHiddenText || ""}
              >
                Hide
              </Text>
            ) : (
              <Box
                onClick={() => {
                  emitTrayClosed(callbackEmit)
                }}
                sx={moodboardConfig?.classes?.headerIcon ? {} : styles.headerIcon}
                cursor="pointer"
                className={moodboardConfig?.classes?.headerIcon || ""}
              >
                <CrossIcon />
              </Box>
            )}
          </HStack>
        </Box>
      </Flex>
    </PopoverHeader>
  );
};

export default Header;

const styles = {
  header: {
    height: "64px",
    backgroundColor: "var(--gray-100, #F5F6FA)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    zIndex: 555,
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    borderColor: "rgb(226, 232, 240)"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerTitleBox: {
    display: "flex",
    alignItems: "center",
  },
  headerActionBox: {
    display: "flex",
    alignItems: "center",
  },
  headerIconBox: {
    display: "flex",
    gap: "12px",
  },
  headerIcon: {
    cursor: "pointer",
    minWidth: "25px",
    minHeight: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitleText: {
    padding: "4px 8px 0",
    minHeight: "30px",
    fontSize: "15px",
    color: "var(--gray-900, #27292C)",
  },
  headerSelect: {
    variant: "ghost",
    size: "sm",
    borderRadius: "md",
    padding: "0 8px",
    height: "32px",
    textAlign: "left",
    ":hover": { backgroundColor: "var(--gray-200, #EEEFF0)" },
  },
  headerSelectText: {
    fontSize: "15px",
    color: "var(--gray-900, #27292C)",
  },
  headerOptionBox: {
    padding: 0,
    border: "none",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: "0px 0px 10px 10px",
    backgroundColor: "white",
    overflow: "hidden",
  },
  headerOption: {
    ":hover": { backgroundColor: "var(--gray-100, #F5F6FA)" },
    height: "30px",
    padding: "7px",
  },
  headerOptionText: {
    fontSize: "12px",
    color: "var(--gray-900, #27292C)",
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
  },
  headerHiddenText: {
    color: "#555A60",
    textAlign: "center",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontSize: "15px",
    fontStyle: "normal",
    lineHeight: "140%",
    textDecoration: "underline",
    cursor: "pointer",
  },
};