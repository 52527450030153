import { GraphQLClient, gql } from 'graphql-request';
import { ProductResponse, ProductResult } from '../state/types';

interface ProductQueryVariables {
  filter: {
    organization: string;
    sku: string;
    catalog: string;
    customFields: Array<{ name: string }>;
  };
}

// Define types for the media query variables and response
interface MediaQueryVariables {
  filter: {
    _id: string;
    organization: string;
  };
}

interface MediaValue {
  value: string;
}

interface MediaDataResponse {
  mediaFindOne: {
    value: MediaValue[];
    _id: string;
  } | null;
}

// Define the type for getProduct parameters
interface GetProductParams {
  skuId: string;
  catalogId: string;
  organizationId: string;
  endpoint:string;
}

// Fetch product data
export const getProductData = async ({ skuId, catalogId, organizationId, endpoint }: GetProductParams) => {

  if(!endpoint) return null
  const client = new GraphQLClient(endpoint);

  const productQuery = gql`
    query ProductFindOne($filter: FilterFindOneproductInput) {
      productFindOne(filter: $filter) {
        catalog
        category
        medias {
          value
          type
        }
        sku
        title
        _id
        customFields {
          name
          value
          type
        }
      }
    }
  `;

  const productVariables: ProductQueryVariables = {
    filter: {
      organization: organizationId,
      sku: skuId,
      catalog: catalogId,
      customFields: [{ name: "$$applications" }]
    }
  };

  try {
    const productData: ProductResponse = await client.request(productQuery, productVariables);
    const mediaId = productData?.productFindOne?.medias?.[0]?.value;
    if (mediaId) {
      const mediaData = await getMediaData(mediaId, organizationId, client);
      const optionsData = productData.productFindOne?.customFields?.find(field => field.name === '$$applications');
      const { _id: id, title, sku } = productData.productFindOne || {};

      const result : ProductResult =  { mediaData, optionsData, title, skuId };

      return result;
    }
    else{
      throw new Error ("The tile data response result is null.")
    }
  } catch (error) {
    console.error('Error fetching product data:', error);
    throw error;
  }
};

// Fetch media data
const getMediaData = async (mediaId: string, organizationId: string, client: GraphQLClient): Promise<MediaDataResponse> => {
  

  const mediaQuery = gql`
    query MediaFindOne($filter: FilterFindOnemediaInput) {
      mediaFindOne(filter: $filter) {
        value {
          value
        }
        _id
      }
    }
  `;

  const mediaVariables: MediaQueryVariables = {
    filter: {
      _id: mediaId,
      organization: organizationId
    }
  };

  try {
    return await client.request<MediaDataResponse>(mediaQuery, mediaVariables);
  } catch (error) {
    console.error('Error fetching media data:', error);
    throw error;
  }
};
