import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { WarnIcon } from "../../utils/svgUtils";
import { moodboardConfigState } from "../../state/store";

interface WarnTooltipProps {
  isVisible: boolean;
  title?:string;
  menubarPosition: {
    left: number | string;
    right: number | string;
    top: number | string;
  };
}

const WarnTooltip: React.FC<WarnTooltipProps> = ({
  isVisible,
  title,
  menubarPosition,
}) => {
  const moodboardConfig = moodboardConfigState.get();

  return (
    <Box
      sx={
        moodboardConfig?.classes?.toolbarOptionText
          ? {
              left: menubarPosition.left,
              right: menubarPosition.right,
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(-10px)",
              pointerEvents: isVisible ? "auto" : "none",
            }
          : {
              ...styles.warnTooltip,
              left: menubarPosition.left,
              right: menubarPosition.right,
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(-10px)",
              pointerEvents: isVisible ? "auto" : "none",
            }
      }
      className={moodboardConfig?.classes?.warnTooltip || ""}
    >
      <Box
        sx={moodboardConfig?.classes?.warnTooltipBox ? {} : styles.warnTooltipBox}
        className={moodboardConfig?.classes?.warnTooltipBox || ""}
      >
        <Box
          sx={moodboardConfig?.classes?.warnTooltipIcon ? {} : styles.warnTooltipIcon}
          className={moodboardConfig?.classes?.warnTooltipIcon || ""}
        >
          <WarnIcon />
        </Box>
        <Text
          sx={moodboardConfig?.classes?.warnTooltipText ? {} : styles.warnTooltipText}
          className={moodboardConfig?.classes?.warnTooltipText || ""}
        >
          {title || ""}
        </Text>
      </Box>
    </Box>
  );
};

export default React.memo(WarnTooltip);

const styles = {
  warnTooltip: {
    position: "absolute",
    top: "0px",
    left: "0",
    right: "0",
    backgroundColor: "var(--gray-900, #27292C)",
    boxShadow: "0px 0px 18px 0px #00000052",
    borderRadius: "10px",
    zIndex: 5555555,
    minWidth: "250px",
    opacity: 0,
    transform: "translateY(-10px)",
    pointerEvents: "none",
    transition: "opacity 0.3s ease, transform 0.3s ease",
  },
  warnTooltipBox: {
    padding: "5px 10px",
    minHeight: "30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
  },
  warnTooltipText: {
    WebkitLineClamp: 2,
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "1.27",
    color: "var(--white, #FFF)",
  },
  warnTooltipIcon: {
    padding: "5px",
  },
};
