import templateLocalConfig from "../configuration/templateLocalConfig.json";
import { Placement, Project } from "../state/types";

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const initialArray = (n:number) => {
  // Ensure n is at least 1 and at most 8
  const length = Math.max(1, Math.min(n, 8));
  return Array.from({ length }, () => ({}));
}

export const getTagPosition = (tagPosition?: string) => {
  switch (tagPosition) {
    case "bottom left":
      return { bottom: "20px", left: 0, right: "auto", top: "auto",  borderRadius: "0px 6px 6px 0px", };
    case "top left":
      return { top: "20px", left: 0, right: "auto", bottom: "auto",  borderRadius: "0px 6px 6px 0px", };
    case "bottom right":
      return { bottom: "20px", right: 0, left: "auto", top: "auto",  borderRadius: "6px 0px 0px 6px", };
    case "top right":
      return { top: "20px", right: 0, left: "auto", bottom: "auto",  borderRadius: "6px 0px 0px 6px", };
    default:
      return { bottom: "20px", left: 0, right: "auto", top: "auto",  borderRadius: "0px 6px 6px 0px", };
  }
};

export const getDynamicStyles = (index: number, length: number) => {
  const configObj =
    templateLocalConfig.default_template?.placement[length][index] ||
    templateLocalConfig.default_template?.placement[0][index];

  const { x, y, layer, height, width } = configObj;

  if (length === 0) {
    return {
      width: width,
      height: height,
    };
  }

  return {
    position: "absolute" as const,
    top: x,
    left: y,
    width: width,
    height: height,
    zIndex: layer,
  };
};

export const getBoundingBoxDimensions = (imageDataArray: Placement[]) => {
  if (!imageDataArray || imageDataArray.length === 0)
    return { width: 0, height: 0 };

  const dataLength = imageDataArray?.length;

  const minYCoordinate = imageDataArray?.sort(
    (a: Placement, b: Placement) => (a?.y || 0) - (b?.y || 0)
  )[0];
  const minXCoordinate = imageDataArray?.sort(
    (a: Placement, b: Placement) => (a?.x || 0) - (b?.x || 0)
  )[0];

  const maxYCoordinate = imageDataArray?.sort(
    (a: Placement, b: Placement) => (a?.y || 0) - (b?.y || 0)
  )[dataLength - 1];
  const maxXCoordinate = imageDataArray?.sort(
    (a: Placement, b: Placement) => (a?.x || 0) - (b?.x || 0)
  )[dataLength - 1];

  const width = (maxXCoordinate?.width || 0) + (maxXCoordinate?.x || 0) + (minXCoordinate?.x || 0);

  const height = (maxYCoordinate?.height || 0) + (maxYCoordinate?.y || 0 ) + (minYCoordinate?.y || 0);

  return { width, height };
};

let nearestPowerOf2 = (n: number) =>
  Math.log2(n) % 1 === 0 ? n : (1 << (31 - Math.clz32(n))) << 1;

const isImage = (url: string) =>
  /\.(jpeg|jpg|gif|png)$/.test(url.toLowerCase());

const isPositiveNumber = (num: number) => num > 0;

const transformMediaServiceUrl = (
  url: string,
  width: number | string
): string => {
  const domainRegex =
    /(https|http):\/\/(apps-assets(-.*)?\.byondxr\.com)\/(.*)/i;
  const match = url.match(domainRegex);

  if (match && match.length === 5) {
    const [, protocol, domain, , path] = match;
    const newUrl = `${protocol}://${domain}/media-service/${path}`;
    const urlObj = new URL(newUrl);
    urlObj.searchParams.append("width", `${width}`);
    return urlObj.toString();
  } else {
    return url;
  }
};

export const optimizeImage = ({
  url,
  imageWidth,
}: {
  imageWidth: number;
  url: string;
}) => {
  const _imageWidth = nearestPowerOf2(imageWidth);

  if (!isImage(url) || !isPositiveNumber(_imageWidth)) {
    return url || "";
  }

  return transformMediaServiceUrl(url, _imageWidth);
};

export const getToolbarPosition = (
  image: Placement,
  templateHeight: number,
  templateWidth: number,
  trayHeight: number,
  toolbarHeight: number,
  toolbarWidth: number
) => {
  const tileCenterHeightCoordinate = (image.y || 0) + (image.height || 0) / 2;
  const halfTemplateHeight = templateHeight / 2;

  const shouldBottomToolBarPlacement =
    halfTemplateHeight - tileCenterHeightCoordinate > trayHeight / 4;

  const tileCenterWidthCoordinate = (image.x || 0) + (image.width || 0) / 2;
  const halfTemplateWidth = templateWidth / 2;

  const shouldLeftToolBarPlacement =
    halfTemplateWidth > tileCenterWidthCoordinate;
  const shouldCenterToolBarPlacement =
    -10 < halfTemplateWidth - tileCenterWidthCoordinate &&
    halfTemplateWidth - tileCenterWidthCoordinate < 10;

  const menubarPosition = shouldCenterToolBarPlacement
    ? {
        left: (image.width || 0) / 2 - toolbarWidth / 2 + "px",
        right: "auto",
        top: shouldBottomToolBarPlacement
          ? `${image.height}px`
          : `-${toolbarHeight}px`,
      }
    : {
        left: shouldLeftToolBarPlacement ? "-8px" : "auto",
        right: shouldLeftToolBarPlacement ? "auto" : "-8px",
        top: shouldBottomToolBarPlacement
          ? `${image.height}px`
          : `-${toolbarHeight}px`,
      };

  return menubarPosition;
};

export function isModified(arr1: any, arr2: any) {
  function deepCompare(obj1: any, obj2: any) {
    if (obj1 === obj2) return false;

    if (typeof obj1 !== typeof obj2) return true;

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return true;

      for (let key of keys1) {
        if (!keys2.includes(key) || deepCompare(obj1[key], obj2[key])) {
          return true;
        }
      }
      return false;
    }

    return obj1 !== obj2;
  }

  if (arr1?.length !== arr2?.length) return true;

  for (let i = 0; i < arr1.length; i++) {
    if (deepCompare(arr1[i], arr2[i])) {
      return true;
    }
  }

  return false;
}

export const getTrayStatus = (
  projects: Project[],
  activeProjectIndex: number,
  imageDataArray: Placement[],
  templateCoordinate: Placement[][],
  isEmptyTemplate: boolean
) => {
  const activeProject = projects[activeProjectIndex]?.data?.map((ele) => {
    const { product, selectedApplication } = ele;
    return { skuId: product?.skuId, selectedApplication };
  });

  const data = imageDataArray?.map((ele) => {
    const { product, selectedApplication } = ele;
    return { skuId: product?.skuId, selectedApplication };
  });

  const emptyData = imageDataArray
    ?.map((ele) => {
      const { product, selectedApplication } = ele;
      return { skuId: product?.skuId, selectedApplication };
    })
    .filter((ele, ind) => {
      if (imageDataArray?.length - 1 === ind) {
        return ele?.skuId;
      } else {
        return true;
      }
    });

  if (projects?.length && activeProjectIndex !== null) {
    const modifiedStatus = isModified(
      activeProject,
      isEmptyTemplate ? emptyData : data
    )
      ? "dirty"
      : "pristine";
    return modifiedStatus;
  } else {
    const dataWithoutId = imageDataArray?.map((ele) => {
      const { id, ...rest } = ele;
      return rest;
    });

    const modifiedStatus = isModified(templateCoordinate[0], dataWithoutId)
      ? "dirty"
      : "pristine";
    return modifiedStatus;
  }
};
