import templateLocalConfig from "../configuration/templateLocalConfig.json";
import templateConfig from "../configuration/templateConfig.json";
import { TemplateConfig, TemplateData } from "../state/types";
import packageJson from "../../package.json";

const templateConfigTyped: TemplateConfig = {...templateLocalConfig, ...templateConfig};

/**
 * Fetches template configuration from S3 via API.
 * Falls back to local config if the API fetch fails.
 * @param key - template name (e.g., "default_template")
 */
export const getTemplate = async (key: keyof TemplateConfig, templateEndpoint:string): Promise<TemplateData> => {

  try {
    if (!templateEndpoint) {
      throw new Error(`template api url does not exist`);
    }

    const templateUrl = templateEndpoint.includes('http') ? templateEndpoint : `https://studio.byondxr.com/products/moodboard/dev/${packageJson.version}/template-config.json`
    // Attempt to fetch from S3 API if the template is not available locally
    const response = await fetch(templateUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ templateName: key })
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch template from API: ${response.statusText}`);
    }

    const data = await response.json();
    
    if (data[key]) {
      return data[key] as TemplateData;
    }
  } catch (error) {
    // console.error("Error fetching template from API, using local configuration:", error);
  }

  // Fall back to local config if API fetch fails or template is empty
  const template : TemplateData = templateConfigTyped[key];
  
  return template;
};