import React from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { CopyIcon, DeleteIcon, InfoIcon } from "../../utils/svgUtils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { moodboardConfigState } from "../../state/store";
import { Config } from "../../state/types";

interface ToolbarMenuProps {
  applications?: string[];
  menubarPosition: {
    left: number | string;
    right: number | string;
    top: number | string;
  };
  handleUpdateCheckedOptions: (
    index: number,
    selectedApplication?: string
  ) => void;
  handleDuplicate: (index: number) => void;
  handleRemove: (index: number) => void;
  index: number;
  selectedApplication?: string;
  title?: string;
  isEmptyTemplate: boolean;
  isEmptyTile: boolean;
  toolbarWidth: number;
}

const ActionButtons: React.FC<{
  onDuplicate: () => void;
  onRemove: () => void;
  showCloneIcon?:boolean;
  showDeleteIcon?:boolean;
  classes: Config["classes"];
}> = React.memo(({ onDuplicate, onRemove, showCloneIcon, showDeleteIcon, classes }) => (
  <Box
    className={classes?.toolbarActionBox || ""}
    sx={classes?.toolbarActionBox ? {} : styles.toolbarActionBox}
  >
    {showCloneIcon && <IconButton
      aria-label="Copy"
      icon={<CopyIcon />}
      onClick={onDuplicate}
      className={classes?.toolbarActionIcon || ""}
      sx={classes?.toolbarActionIcon ? {} : styles.toolbarActionIcon}
    />}
    
    {showDeleteIcon && <IconButton
      aria-label="Delete"
      icon={<DeleteIcon />}
      onClick={onRemove}
      className={classes?.toolbarActionIcon || ""}
      sx={classes?.toolbarActionIcon ? {} : styles.toolbarActionIcon}
    />}
  </Box>
));

const Header: React.FC<{
  title: string;
  showActions: boolean;
  noOfLines?: number;
  showInfoIcon?: boolean;
  showDeleteIcon?: boolean;
  showCloneIcon?: boolean;
  onDuplicate: () => void;
  onRemove: () => void;
  classes: Config["classes"];
}> = ({
  title,
  showActions,
  showInfoIcon = false,
  showDeleteIcon= true,
  showCloneIcon= true,
  noOfLines = 1,
  onDuplicate,
  onRemove,
  classes,
}) => (
  <Box
    className={classes?.toolbarHeader || ""}
    sx={classes?.toolbarHeader ? {} : styles.toolbarHeader}
  >
    {showInfoIcon && (
      <Box
        className={classes?.toolbarHeaderIcon || ""}
        sx={classes?.toolbarHeaderIcon ? {} : styles.toolbarHeaderIcon}
      >
        <InfoIcon />
      </Box>
    )}
    <Text
      noOfLines={noOfLines}
      className={classes?.toolbarHeaderText || ""}
      sx={classes?.toolbarHeaderText ? {} : styles.toolbarHeaderText}
    >
      {title}
    </Text>
    {showActions && (
      <ActionButtons
        onDuplicate={onDuplicate}
        onRemove={onRemove}
        showDeleteIcon={showDeleteIcon}
        showCloneIcon={showCloneIcon}
        classes={classes}
      />
    )}
  </Box>
);

const ToolbarMenu: React.FC<ToolbarMenuProps> = ({
  applications,
  menubarPosition,
  handleUpdateCheckedOptions,
  handleDuplicate,
  handleRemove,
  index,
  selectedApplication,
  title = "Select",
  isEmptyTemplate,
  isEmptyTile,
  toolbarWidth,
}) => {
  const handleOptionChange = (value: string) => {
    handleUpdateCheckedOptions(
      index,
      selectedApplication === value ? "" : value
    );
  };

  const moodboardConfig = moodboardConfigState.get();

  return (
    <Box
      className={moodboardConfig?.classes?.toolbar || ""}
      sx={
        moodboardConfig?.classes?.toolbar
          ? {
              top: menubarPosition.top,
              left: menubarPosition.left,
              right: menubarPosition.right,
              minWidth: toolbarWidth,
            }
          : {
              ...styles.toolbar,
              top: menubarPosition.top,
              left: menubarPosition.left,
              right: menubarPosition.right,
              minWidth: toolbarWidth,
            }
      }
    >
      {!isEmptyTile ? (
        <Header
          title={title}
          showActions={!isEmptyTemplate && !isEmptyTile }
          showCloneIcon={!(!isEmptyTemplate && !isEmptyTile)}
          onDuplicate={() => handleDuplicate(index)}
          onRemove={() => handleRemove(index)}
          classes={moodboardConfig?.classes}
        />
      ) : (
        <Header
          noOfLines={2}
          showInfoIcon={true}
          title="add a tile here by clicking ‘add to board’ icon or dragging it to the tray"
          showActions={false}
          onDuplicate={() => {}}
          onRemove={() => {}}
          classes={moodboardConfig?.classes}
        />
      )}

      {isEmptyTemplate && !isEmptyTile && (
        <Box
          className={moodboardConfig?.classes?.toolbarFooter || ""}
          sx={moodboardConfig?.classes?.toolbarFooter ? {} : styles.toolbarFooter}
        >
          {applications && applications?.length > 0 && (
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="outline"
                className={moodboardConfig?.classes?.toolbarSelect || ""}
                sx={moodboardConfig?.classes?.toolbarSelect ? {} : styles.toolbarSelect}
              >
                <Text
                  className={moodboardConfig?.classes?.toolbarSelectText || ""}
                  sx={moodboardConfig?.classes?.toolbarSelectText ? {} : styles.toolbarSelectText}
                >
                  {selectedApplication || "Select application"}
                </Text>
              </MenuButton>
              <MenuList
                className={moodboardConfig?.classes?.toolbarOptionBox || ""}
                sx={moodboardConfig?.classes?.toolbarOptionBox ? {} : styles.toolbarOptionBox}
              >
                <MenuOptionGroup type="checkbox" value={selectedApplication ? [selectedApplication] : []}>
                  {applications && applications?.length > 0 &&
                    applications.map((application) => (
                      <MenuItemOption
                        key={application}
                        value={application}
                        onClick={() => handleOptionChange(application)}
                        className={
                          moodboardConfig?.classes?.toolbarOption || ""
                        }
                        sx={moodboardConfig?.classes?.toolbarOption ? {} : styles.toolbarOption}
                      >
                        <Text
                          className={
                            moodboardConfig?.classes?.toolbarOptionText || ""
                          }
                          sx={moodboardConfig?.classes?.toolbarOptionText ? {} : styles.toolbarOptionText}
                        >
                          {application}
                        </Text>
                      </MenuItemOption>
                    ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          )}
          <ActionButtons
            onDuplicate={() => handleDuplicate(index)}
            onRemove={() => handleRemove(index)}
            classes={moodboardConfig?.classes}
            showDeleteIcon={true}
            showCloneIcon={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ToolbarMenu);

const styles = {
  toolbar: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    backgroundColor: "white",
    boxShadow: "0px 0px 18px 0px #00000052",
    borderRadius: "8px",
  },
  toolbarHeader: {
    padding: "3.5px 10px",
    minHeight: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  toolbarHeaderText: {
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "1.27",
    color: "var(--gray-800, #404449)",
  },
  toolbarHeaderIcon: {
    padding: "10px",
  },
  toolbarFooter: {
    borderTop: "1px solid var(--gray-300, #E1E3E5)",
    display: "flex",
    gap: "6px",
    padding: "0 6px",
    alignItems: "center",
    height: "40px",
  },
  toolbarSelect: {
    fontSize: "14px",
    height: "30px",
    flex: 1,
  },
  toolbarSelectText: {
    fontSize: "12px",
    color: "var(--gray-600, #797F84)",
    textAlign: "start",
  },
  toolbarOptionText: {
    fontSize: "12px",
    color: "var(--gray-900, #27292C)",
  },
  toolbarOptionBox: {},
  toolbarOption: {},
  toolbarActionBox: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  toolbarActionIcon: {
    backgroundColor: "transparent",
    fontSize: "14px",
    height: "30px",
    width: "30px",
    padding: "0px !important"
  },
};
