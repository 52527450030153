// src/TemplateDialog.tsx
import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
} from "@chakra-ui/react";

const TemplateRenderer = ({ template, onClick }: any) => {
  return (
    <Button key={template.key} onClick={onClick}>
      {template.name || "Empty Template"}
    </Button>
  );
};

function TemplateDialog({ selectTemplate, templates,  isOpen,
  onClose  }: any) {

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size={"xl"}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexDirection="column" gap="5px">
              {templates.map((template: any) => (
                <TemplateRenderer
                  key={template.key}
                  template={template}
                  onClick={() => {
                    selectTemplate({key:template.key});
                    onClose();
                  }}
                />
              ))}
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TemplateDialog;
