import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Moodboard from "./components/MoodBoard";
import { setMoodboardEvents } from "./utils/moodboardEvents";
import "./global.css";

const App = () => {

  useEffect(() => {
    setMoodboardEvents()
  },[])

  return (
    <aside className="mb-widget">
      <Moodboard />
    </aside>
  );
};

export default App;