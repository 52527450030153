import { useMoodboardState } from "./useMoodBoardState";
import { optimizeImage } from "../utils/commonUtils";
import { useEffect } from "react";
import {
  DraggedImageData,
  Placement,
  Product,
  ProductResult,
} from "../state/types";
import { emitProductAdded, emitProductRemoved } from "../utils/moodboardEvents";

export const useDefaultTemplateHandler = () => {
  const {
    imageData,
    moodboardConfig,
    draggedImageData,
    isDropdownOpenIndex,
    projects,
    activeProjectIndex,
    templateCoordinate,
    isTemplateLoading,
    setImageData,
    setDraggedImageData,
    setIsDropdownOpenIndex,
    setTemplate,
  } = useMoodboardState();

  const callbackEmit = moodboardConfig?.debugMode ? console.log : () => {};

  const templateIndex = templateCoordinate.length - 1;
  const imageDataArray = templateCoordinate.length
    ? templateCoordinate[templateIndex]
    : [];
  const dynamicStyle = imageData[templateIndex];

  useEffect(() => {
    const newImageData: Placement[] = projects[activeProjectIndex]?.data;
    if (newImageData) {
      const template = projects[activeProjectIndex]?.template;
      if (template?.key === "empty_template") {
        setTemplate(template);
      }
    }
    setIsDropdownOpenIndex(null);
    setImageData(newImageData || imageDataArray);
  }, [activeProjectIndex, projects, templateCoordinate]);

  const handleAddToTrey = async (
    addToTreyImageData: DraggedImageData | null,
    targetIndex: string | null
  ) => {
    const index = findIndex(targetIndex);
    const canDropTile =
      imageData.filter((ele) => ele?.product?.skuId).length < imageData.length || index > -1

    if (canDropTile) {
      const loadingImage: Product = {
        src: "",
        applications: [],
        loading: true,
        skuId: "",
        title: "",
      };

      setImageData((prev) => replaceEmptySlot(prev, index, loadingImage));

      try {
        const { mediaData, optionsData, title, skuId } =
          addToTreyImageData?.data as ProductResult;
        const fetchedImage = {
          src: optimizeImage({
            url: mediaData.mediaFindOne.value[0].value,
            imageWidth: dynamicStyle?.width || 0,
          }),
          applications: optionsData?.value || [],
          title,
          loading: false,
          skuId,
        };

        imageData[index]?.selectedApplication &&
          optionsData?.value?.includes(imageData[index]?.selectedApplication) &&
          emitProductAdded(skuId, callbackEmit);
        setImageData((prev) => {
          return replaceEmptySlot(prev, index, fetchedImage);
        });
        setDraggedImageData(null);
        setIsDropdownOpenIndex(index);
      } catch (error) {
        // console.error("fetch productData error", error);
        setImageData((prev) => resetImageAtIndex(prev, index));
      }
    }
  };

  const findIndex = (targetIndex: string | null) => {
    let index
    if (targetIndex !== null) {
      index =
      targetIndex !== null && Number(targetIndex) >= 0
        ? Number(targetIndex)
        : imageData.findIndex((ele) => !ele.product?.skuId) || 0;
    } else {
      if (isDropdownOpenIndex !== null) {
        if (imageData[isDropdownOpenIndex]?.product?.skuId) {
          index = imageData.findIndex((ele) => !ele.product?.skuId) || 0;
        } else {
          index = isDropdownOpenIndex;
        }
      }
      else{
        index = imageData.filter((ele) => ele?.product?.skuId).length === imageData.length ? -1 : 0
      }
    }
    return index || 0
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const targetIndex = target.getAttribute("data-index");
    draggedImageData && (await handleAddToTrey(draggedImageData, targetIndex));
  };

  const handleDuplicate = (index: number, callback: (value: boolean) => void): void => {
    return;
  };

  const handleRemove = (index: number) => {
    setImageData((prev: Placement[]) =>
      prev.map((ele: Placement, ind: number) => {
        if (ind === index) {
          const { x, y, layer, height, width, selectedApplication } = ele;
          return { x, y, layer, height, width, selectedApplication };
        }
        return ele;
      })
    );
    const sku = imageData[index]?.product?.skuId || "";
    emitProductRemoved(sku, callbackEmit);
    setIsDropdownOpenIndex(null);
  };

  return {
    handleAddToTrey,
    handleDrop,
    handleDuplicate,
    handleRemove,
    imageDataArray: isTemplateLoading ? [] : imageData,
  };
};

// Helper functions
const replaceEmptySlot = (
  prev: Placement[],
  index: number,
  newImage: Product
) => {
  return prev.map((ele, idx) => {
    const isOptionAvailable =
      ele?.selectedApplication &&
      newImage?.applications?.includes(ele?.selectedApplication);
    return idx === index && isOptionAvailable
      ? { ...ele, product: newImage }
      : ele;
  });
};

const resetImageAtIndex = (prev: Placement[], index: number) => {
  return prev.map((ele, idx) =>
    index === idx ? resetImageProperties(ele) : ele
  );
};

const resetImageProperties = (ele: Placement) => {
  const { x, y, layer, height, width, selectedApplication } = ele;
  return { x, y, layer, height, width, selectedApplication };
};
