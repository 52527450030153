import React, { useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { observer } from "@legendapp/state/react";
import bgImage from "../assets/images/tray-bg.png";
import { Layer, Stage } from "react-konva";
import { Html } from "react-konva-utils";
import Template from "./template/Template";
import { DraggedImageData, Placement} from "../state/types";
import { moodboardConfigState } from "../state/store";

interface DropZoneProps {
  imageDataArray: Placement[];
  isDropdownOpenIndex: number | null;
  setIsDropdownOpenIndex: (index: number | null) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  handleToggleDropdown: (e: React.MouseEvent, index: number | null) => void;
  handleUpdateCheckedOptions: (index: number, selectedApplication?: string) => void;
  handleDuplicate: (index: number, callback:(value:boolean)=>void) => void;
  handleRemove: (index: number) => void;
  isEmptyTemplate: boolean;
  draggedImageData: DraggedImageData | null;
  setImageData: (data: Placement[] | ((prev: Placement[]) => Placement[])) => void;
}

const DropZone: React.FC<DropZoneProps> = observer(
  ({
    imageDataArray,
    isDropdownOpenIndex,
    handleDrop,
    handleToggleDropdown,
    handleUpdateCheckedOptions,
    handleDuplicate,
    handleRemove,
    isEmptyTemplate,
    draggedImageData,
    setImageData,
    setIsDropdownOpenIndex
  }) => {

    const centerRef = useRef<HTMLDivElement>(null);
    const templateRef = useRef() as React.RefObject<HTMLDivElement>;

    const [dragStartClient, setDragStartClient] = useState({ x: 0, y: 0 });

    const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);

    const moodboardConfig = moodboardConfigState.get()

    const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
      if (!centerRef.current && (e.target === centerRef.current)) {
        const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const clientY = "touches" in e ? e.touches[0].clientY : e.clientY;
        setIsDragging(true);
        setDragStartClient({ x: clientX, y: clientY });
      }
    };

    const handleDragEnd = (e: React.MouseEvent | React.TouchEvent) => {
      setIsDragging(false);
    };

    const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
      if (isDragging) {
        const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const clientY = "touches" in e ? e.touches[0].clientY : e.clientY;

        const deltaX = clientX - dragStartClient.x;
        const deltaY = clientY - dragStartClient.y;
        setDragPosition({ x: deltaX, y: deltaY });
      }
    };

    return (
      <Box
        sx={moodboardConfig?.classes?.dropArea ? {backgroundImage: `url(${bgImage})`} : {...styles.dropArea, backgroundImage: `url(${bgImage})`,}}
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        className={moodboardConfig?.classes?.dropArea || ""}
      >
        <Stage style={moodboardConfig?.classes?.dropContainer ? {} : styles.dropContainer } className={moodboardConfig?.classes?.dropContainer || ""}>
          <Layer>
            <Html
              divProps={{
                style: moodboardConfig?.classes?.dropZone ? {} : styles.dropZone,
                className: moodboardConfig?.classes?.dropZone || ""
              }}
            >
              <Box
                sx={moodboardConfig?.classes?.dropBox ? {} : styles.dropBox}
                onMouseDown={handleDragStart}
                onTouchStart={handleDragStart}
                onMouseUp={handleDragEnd}
                onTouchEnd={handleDragEnd}
                onMouseMove={handleDragMove}
                onTouchMove={handleDragMove}
                className={moodboardConfig?.classes?.dropBox || ""}
              >
                <Template
                  {...{
                    ref: centerRef,
                    templateRef:templateRef,
                    isDropdownOpenIndex,
                    imageDataArray,
                    handleToggleDropdown,
                    handleUpdateCheckedOptions,
                    handleDuplicate,
                    handleRemove,
                    isEmptyTemplate,
                    draggedImageData,
                    setImageData,
                    dragPosition,
                    setIsDropdownOpenIndex
                  }}
                />
              </Box>
            </Html>
          </Layer>
        </Stage>
      </Box>
    );
  }
);

export default React.memo(DropZone);

const styles = {
  dropArea: {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dropContainer:{ 
    height: "100%", 
    width: "100%" 
  },
  dropZone:{
    height: "100%",
    width: "100%",
    top: "0px",
    left: "0px",
    transformOrigin: "left top",
  },
  dropBox: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }
}
