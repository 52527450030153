import {
  activeProjectIndexState,
  draggedImageDataState,
  imageDataState,
  isDropdownOpenIndexState,
  moodboardConfigState,
  projectNameState,
  projectsState,
  templateState,
  templateCoordinateState,
  isTemplateLoadingState,
  apiConfigState
} from "../state/store";
import { Config } from "../state/types";

export const useMoodboardState = () => {
  return {
    moodboardConfig: moodboardConfigState.get(),
    imageData: imageDataState.get(),
    draggedImageData: draggedImageDataState.get(),
    isDropdownOpenIndex: isDropdownOpenIndexState.get(),
    projects: projectsState.get(),
    activeProjectIndex: activeProjectIndexState.get(),
    projectName: projectNameState.get(),
    template:templateState.get(),
    templateCoordinate:templateCoordinateState.get(),
    isTemplateLoading:isTemplateLoadingState.get(),
    apiConfig:apiConfigState.get(),
    setMoodboardConfigState: (config:Config) => moodboardConfigState.set((prev:Config) => {
      const classes = {...prev.classes, ...config.classes};
      return {...prev, ...config, classes}
    }), 
    setImageData: imageDataState.set,
    setDraggedImageData: draggedImageDataState.set,
    setIsDropdownOpenIndex: isDropdownOpenIndexState.set,
    setProjects: projectsState.set,
    setActiveProjectIndex: activeProjectIndexState.set,
    setProjectName: projectNameState.set,
    setTemplate:templateState.set,
    setTemplateCoordinate:templateCoordinateState.set,
    setIsTemplateLoading:isTemplateLoadingState.set,
    setApiConfig:apiConfigState.set,
  };
};
